@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
}

.nostyle-link {
  text-decoration: none;
  color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#podium-website-widget {
  display: none;
}

.MuiFormControlLabel-root {
  margin-right: 0 !important;
}

.MuiFab-root {
  z-index: 11;
}

.MTableHeader-header-100 {
  z-index: 2;
}

.googleSignInWrapper {
  height: 50px;
  width: 240px;
  background-color: #4285f4;
  color: #fff;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: 0.3s ease;

  &:hover {
    background-color: #4285f4;
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  }

  .googleSignInContent {
    border: 1px solid transparent;
    height: 100%;
    width: 100%;
    .googleSignInLogo {
      padding: 15px;
      float: left;
      display: block;
      background-color: #fff;
      .googleSignInLogoContent {
        width: 18px;
        height: 18px;
        display: block;
      }
    }

    .googleSignInText {
      font-size: 16px;
      font-family: "Roboto", arial, sans-serif;
      line-height: 48px;
      color: #fff;
      margin-left: 6px;
      margin-right: 6px;
      vertical-align: top;
      font-weight: 500;
      letter-spacing: 0.21px;
      text-align: center;
      text-decoration: none;
    }
  }
}

.filepond--root {
  margin-bottom: 0 !important;
}

.react-transform-component {
  width: 48vw !important;
  height: 70vh !important;
}

.loadingContainer {
  position: absolute;
  top: calc(50vh - 25px);
  left: calc(50vw - 25px);
}

.pin-offset {
  pointer-events: none;
  transform: translateY(-15px);
}

.pin {
  pointer-events: none;
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  pointer-events: none;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  pointer-events: none;
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
